<template>
  <v-container fluid class="pa-0 ma-0 fill-height text-left">
    <v-row justify="start">
      <v-col cols="12">
        <v-card class="d-flex fill-height fluid pa-0 ma-0" tile width="100%">
          <v-scroll-x-transition appear>
            <v-img
              src="@/assets/images/hospital-angeles.jpg"
              transition="slide-x-transition"
              position="top"
              width="100%"
              height="300"
              max-height="300"
            >
              <v-container
                fluid
                class="fill-height justify-center align-end bottom-gradient"
              >
                <v-row align="center" justify="center">
                  <v-col>
                    <div class="white--text pb-10">
                      <span
                        class="pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black"
                        style="text-shadow: 2px 2px 10px black"
                      >
                        Our reliable Hospital Catalog
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-scroll-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>

    <v-row justify="center">
      <SearchBar :data="hospitals" @search="updateFilteredData" />
    </v-row>

    <v-row justify="center" align="center">
      <div
        class="fill-height container--fluid mt-8 mb-8 mx-5"
        style="width: 100%"
      >
        <v-container fluid class="fill-height">
          <v-row justify="start" align="center">
            <v-col
              v-for="(item, index) in filteredData"
              :key="index"
              class="d-flex child-flex mb-5"
              cols="12"
              sm="6"
              md="4"
            >
              <v-card hover class="rounded-lg" v-if="item.active">
              <div style="cursor: pointer; height: 300px; overflow: hidden" v-on:click="showProfile(item.id)">
                
                <v-img
                  :src="
                    item.pictures[0] != null
                      ? item.pictures[0].url
                      : require('@/assets/images/no-image.jpg')
                  "
                  :lazy-src="require('@/assets/images/no-image.jpg')"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  aspect-ratio="1"
                  height="300"
                  class="rounded-lg white--text align-end justify-start elevation-2"
                  @click="showProfile(item.id)"
                >
                  
                  <v-btn
                    v-model="selectedId"
                    dark
                    plain
                    text
                    elevation="0"
                    class="ma-2"
                    v-on:click="showProfile(item.id)"
                  >
                    <span
                      class="text-uppercase font-weight-bold"
                      v-text="item.name"
                    ></span>
                  </v-btn>

                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <img v-if="item.basic===1 
                  || item.id == 8 
                  || item.id == 7
                  || item.id == 6
                  || item.id == 5

                   " src="@/assets/images/icons/basic.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-else-if="item.premium===1
                        || item.id == 27 
                        || item.id == 23
                        || item.id == 24
                        || item.id == 26
                        " 
                       src="@/assets/images/icons/premium.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-else src="@/assets/images/icons/member.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-show="item.excellence===1 
                        || item.id == 27 
                        || item.id == 23
                        || item.id == 24
                        || item.id == 26
                        " src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                       class="superior-derecha-segunda"/>
                  <img v-show="item.repsabi===1
                        || item.id == 27 
                        || item.id == 23
                        || item.id == 24
                        || item.id == 26                      
                      " src="@/assets/images/icons/repssabi.png" alt="sello1"
                       class="superior-derecha-tercera"/>                
                </div>   
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="hospitals.length === 0">
      <div style="text-align: center;">
        <div>
          {{ this.message }}
        </div>
        <div style="display: inline-block;margin-top: -15px;">
          <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import hospitalsService from "@/providers/HospitalsService";
import SearchBar from "../searchBar/SearchBar.vue";

export default {
  name: "HospitalsComponent",

  components: {
    SearchBar,
  },

  data: () => ({
    selectedId: 0,
    hospitals: [],
    filteredData: [],
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading our list of excellence hospitals, please be patient ...",
  }),
  methods: {
    getHospitals() {
      hospitalsService.getViewList(true).then((record) => {
        this.hospitals = record.value;
        this.filteredData = [...this.hospitals];

        if (this.hospitals.length === 0) {
          this.message = "There are no Hospitals in the catalogue...";
        }
      });
    },

    updateFilteredData(filteredData) {
      this.filteredData = filteredData;
    },

    showProfile(idHospital) {
      this.selectedId = idHospital;
      this.$router.push({
        name: "HospitalProfile",
        params: {
          id: idHospital,
        },
      });
    },
  },
  mounted() {
    this.hospitals = [];
    this.filteredData = [];
    this.getHospitals();
  },
  // :src="require(`@/assets/images/hospitals/hospital${n}.jpg`)"
  // :lazy-src="require('@/assets/images/no-image.jpg')"
};
</script>

<style scoped>
/* body {
        @apply antialiased text-green-900 bg-green-50;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    }  */
.superior-derecha {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 80px;
  height: 80px;
}

.superior-derecha-segunda {
  position: absolute;
  top: 60px;
  right: 3px;
  width: 95px;
  height: 95px;
}

.superior-derecha-tercera {
  position: absolute;
  top: 160px;
  right: 12px;
  width: 70px;
  height: 70px;
}    
@media (max-width: 767px) {
  .superior-derecha {
    position: absolute;
    top: -30px;
    right: -25px;
    width: 90px;
    height: 80px;
  }

  .superior-derecha-segunda {
    position: absolute;
    top: 50px;
    right: -3px;
    width: 95px;
    height: 95px;
  }

  .superior-derecha-tercera {
    position: absolute;
    top: 145px;
    right: 7px;
    width: 70px;
    height: 70px;
  }
}    
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(163, 153, 248, 0.295) 0%,
    rgba(101, 99, 248, 0.699) 100%
  );
}
#my-lax .v-parallax__content {
  background: linear-gradient(45deg, black, transparent);
}
.v-btn {
  text-transform: none !important;
}
.blend-opacity {
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}
.blend-opacity:hover {
  opacity: 0.9;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: darken;
}
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 50px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
